
// import React, { useEffect, useState } from 'react';
// import { FaCheckCircle, FaSyncAlt } from 'react-icons/fa';
// import { BasisCurve } from 'react-svg-curve';
// import { useNavigate } from 'react-router-dom';
// import './JobPipeline.css';

// // Job Nodes Definition
// const initialJobNodes = [
//   { id: '1', label: 'Verifying member eligibility', type: 'build', status: 'pending', position: { x: 50, y: 150 } },
//   { id: '2', label: 'Parsing the data', type: 'build', status: 'pending', position: { x: 50, y: 250 } },
//   { id: '3', label: 'Question answering with query engines', type: 'test', status: 'pending', position: { x: 350, y: 150 } },
//   { id: '4', label: 'Processing the cost sharing', type: 'test', status: 'pending', position: { x: 350, y: 250 } },
//   { id: '5', label: 'Processing tariff and nonpayables', type: 'test', status: 'pending', position: { x: 350, y: 350 } },
//   { id: '6', label: 'Highlighting documents', type: 'build', status: 'pending', position: { x: 350, y: 450 } },
//   { id: '7', label: 'Saving results', status: 'pending', position: { x: 650, y: 150 } },
// ];


// const CustomNode = ({ label, type, status, position }) => (
//   <div
//     className="custom-node"
//     style={{
//       position: 'absolute',
//       left: position.x,
//       top: position.y,
//       padding: '10px',
//       border: '1px solid #ddd',
//       borderRadius: '30px',
//       backgroundColor: '#fff',
//       textAlign: 'center',
//       width: '150px',
//     }}
//   >
//     <div className="node-label">
//       {label} ({type})
//     </div>
//     <div className="job-status">
//       {status === 'completed' && <FaCheckCircle style={{ color: 'green' }} />}
//       {status === 'in-progress' && <FaSyncAlt className="fa-sync-alt" style={{ color: '#9E9E9E' }} />}
//       {status === 'pending' && <FaSyncAlt style={{ color: '#ccc' }} />}
//     </div>
//   </div>
// );

// const JobPipeline = () => {
//   const [jobNodes, setJobNodes] = useState(initialJobNodes);
//   const navigate = useNavigate(); // For programmatic navigation
//   const [allTasksCompleted, setAllTasksCompleted] = useState(false); // State to track if all tasks are completed

//   // Function to start the jobs automatically when the component mounts
//   const startJobs = async () => {
//     try {
//       const response = await fetch('http://localhost:5000/run-jobs', { method: 'POST' });
//       const data = await response.json();
//       console.log("Jobs started:", data);
//     } catch (error) {
//       console.error("Error starting jobs:", error);
//     }
//   };

//   // Enforce sequential display of completed jobs
//   const updateJobNodesSequentially = (data) => {
//     const updatedNodes = jobNodes.map((node, index) => {
//       // If the previous task is not completed, keep current task as 'pending'
//       if (index > 0 && jobNodes[index - 1].status !== 'completed') {
//         return { ...node, status: 'pending' };
//       }

//       // Otherwise, update the task based on the real status
//       const taskStatus = data[`task${node.id}`];
//       return { ...node, status: taskStatus };
//     });

//     setJobNodes(updatedNodes);

//     // Check if all tasks are completed
//     const allCompleted = updatedNodes.every((node) => node.status === 'completed');
//     setAllTasksCompleted(allCompleted);
//   };

//   // useEffect hook to trigger job execution and poll for status updates
//   useEffect(() => {
//     // Start jobs when the component mounts (on page load)
//     startJobs();

//     // Poll the API every 2 seconds to check job statuses
//     const interval = setInterval(async () => {
//       try {
//         const response = await fetch('http://localhost:5000/status');
//         const data = await response.json();
//         console.log("Job statuses:", data);

//         updateJobNodesSequentially(data); // Ensure jobs display in sequence
//       } catch (error) {
//         console.error("Error fetching job statuses:", error);
//       }
//     }, 2000);

//     // Cleanup the interval on component unmount
//     return () => clearInterval(interval);
//   }, [jobNodes]);  // The dependency array includes jobNodes to update when they change

//   // Redirect to /claim-details if all tasks are completed
//   useEffect(() => {
//     if (allTasksCompleted) {
//       console.log("All tasks are completed! Redirecting to /claim-details...");
//       navigate('/claim-details'); // Redirect to claim-details page
//     }
//   }, [allTasksCompleted, navigate]); // Trigger navigation when allTasksCompleted changes

//   return (
//     <div style={{ position: 'relative', width: '100%', height: '600px' }}>
//       <h2 style={{ textAlign: 'left', marginBottom: '20px', paddingLeft: "50px" }}>Initiating Claim Review</h2>

//       <svg width="100%" height="100%">
//         {/* Draw Connections Between Jobs Using BasisCurve */}
//         <BasisCurve data={[[200, 100], [360, 120]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 200], [360, 200]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 200], [370, 280]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 200], [360, 300], [360, 390]]} stroke="gray" strokeWidth={2} />

//         <BasisCurve data={[[500, 400], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 300], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 200], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 100], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//       </svg>

//       {/* Render Job Nodes */}
//       {jobNodes.map((node) => (
//         <CustomNode
//           key={node.id}
//           label={node.label}
//           type={node.type}
//           status={node.status}
//           position={node.position}
//         />
//       ))}
//     </div>
//   );
// };

// export default JobPipeline;







// JobPipeline.jsx

// import React, { useEffect, useState, useCallback } from 'react';
// import { FaCheckCircle, FaSyncAlt, FaExclamationCircle } from 'react-icons/fa';
// import { BasisCurve } from 'react-svg-curve';
// import { useNavigate } from 'react-router-dom';
// import './JobPipeline.css';

// const initialJobNodes = [
//   { id: '1', label: 'Fetching results', backendKey: 'fetch_results', status: 'pending', position: { x: 50, y: 150 } },
//   { id: '2', label: 'Analyzing documents', backendKey: 'analyze_documents', status: 'pending', position: { x: 350, y: 150 } },
//   { id: '3', label: 'Saving results', backendKey: 'save_results_json', status: 'pending', position: { x: 350, y: 250 } },
//   { id: '4', label: 'Generating discrepancy', backendKey: 'generate_discrepancy', status: 'pending', position: { x: 350, y: 350 } },
//   { id: '5', label: 'Processing tariff', backendKey: 'process_tariff', status: 'pending', position: { x: 350, y: 450 } },
//   { id: '6', label: 'Generating settlement letter', backendKey: 'generate_settlement_letter', status: 'pending', position: { x: 350, y: 550 } },
//   { id: '7', label: 'Highlighting patient documents', backendKey: 'highlight_patient_documents', status: 'pending', position: { x: 650, y: 150 } },
// ];

// const CustomNode = ({ label, status, position }) => (
//   <div
//     className="custom-node"
//     style={{
//       position: 'absolute',
//       left: position.x,
//       top: position.y,
//       padding: '10px',
//       border: '1px solid #ddd',
//       borderRadius: '30px',
//       backgroundColor: '#fff',
//       textAlign: 'center',
//       width: '150px',
//     }}
//   >
//     <div className="node-label">{label}</div>
//     <div className="job-status">
//       {status === 'completed' && <FaCheckCircle style={{ color: 'green' }} />}
//       {status === 'in-progress' && <FaSyncAlt className="fa-spin" style={{ color: '#9E9E9E' }} />}
//       {status === 'pending' && <FaSyncAlt style={{ color: '#ccc' }} />}
//       {status === 'error' && <FaExclamationCircle style={{ color: 'red' }} />}
//     </div>
//   </div>
// );

// const JobPipeline = () => {
//   const [jobNodes, setJobNodes] = useState(initialJobNodes);
//   const navigate = useNavigate();
//   const [allTasksCompleted, setAllTasksCompleted] = useState(false);
//   const [error, setError] = useState(null);

//   const updateJobNodesSequentially = useCallback((progress) => {
//     setJobNodes((prevNodes) => {
//       const updatedNodes = prevNodes.map((node) => ({
//         ...node,
//         status: progress[node.backendKey] || node.status
//       }));

//       const allCompleted = updatedNodes.every((node) => node.status === 'completed');
//       setAllTasksCompleted(allCompleted);

//       return updatedNodes;
//     });
//   }, []);

//   const fetchProgress = useCallback(async () => {
//     try {
//       const response = await fetch('http://localhost:5000/save_pipeline');  // Update port if necessary
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const data = await response.json();

//       console.log('Progress fetched from backend:', data.progress);

//       if (data && data.progress) {
//         updateJobNodesSequentially(data.progress);
//       } else {
//         console.error('No progress data returned from backend');
//       }
//     } catch (error) {
//       console.error('Error fetching job progress:', error);
//       setError('Failed to fetch progress. Please try again later.');
//     }
//   }, [updateJobNodesSequentially]);

//   useEffect(() => {
//     const interval = setInterval(fetchProgress, 2000);
//     return () => clearInterval(interval);
//   }, [fetchProgress]);

//   useEffect(() => {
//     if (allTasksCompleted) {
//       console.log('All tasks are completed! Redirecting to /claim-details...');
//       navigate('/pipe');  // Update the route as per your application
//     }
//   }, [allTasksCompleted, navigate]);

//   useEffect(() => {
//     const startPipeline = async () => {
//       try {
//         const response = await fetch('http://localhost:5000/run_pipeline', { method: 'POST' });
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         console.log('Pipeline started successfully');
//       } catch (error) {
//         console.error('Error starting pipeline:', error);
//         setError('Failed to start the pipeline. Please try again later.');
//       }
//     };

//     startPipeline();
//   }, []);

//   if (error) {
//     return <div className="error-message">{error}</div>;
//   }

//   return (
//     <div style={{ position: 'relative', width: '100%', height: '600px' }}>
//       <h2 style={{ textAlign: 'left', marginBottom: '20px', paddingLeft: '50px' }}>Initiating Claim Review</h2>

//     <svg width="100%" height="100%">
//     {/* Draw Connections Between Jobs Using BasisCurve */}
//        <BasisCurve data={[[200, 90], [360, 90]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 90], [360, 200]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 90], [370, 280]]} stroke="gray" strokeWidth={2} />
//          <BasisCurve data={[[200, 90], [360, 300], [360, 390]]} stroke="gray" strokeWidth={2} />
//          <BasisCurve data={[[200, 90], [320, 310], [360, 490]]} stroke="gray" strokeWidth={2} />

//         <BasisCurve data={[[500, 400], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//          <BasisCurve data={[[500, 300], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//          <BasisCurve data={[[500, 200], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 100], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 500], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
//       </svg>


//       {jobNodes.map((node) => (
//         <CustomNode
//           key={node.id}
//           label={node.label}
//           status={node.status}
//           position={node.position}
//         />
//       ))}
//     </div>
//   );
// };

// export default JobPipeline;







import React, { useEffect, useState, useCallback } from 'react';
import { FaCheckCircle, FaSyncAlt, FaExclamationCircle } from 'react-icons/fa';
import { BasisCurve } from 'react-svg-curve';
import { useNavigate } from 'react-router-dom';
import './JobPipeline.css';
import greenTick from './green_right.png';

const initialJobNodes = [
  { id: '1', label: 'Fetching results', backendKey: 'fetch_results', status: 'pending', position: { x: 50, y: 150 } },
  { id: '2', label: 'Analyzing documents', backendKey: 'analyze_documents', status: 'pending', position: { x: 350, y: 150 } },
  { id: '3', label: 'Saving results', backendKey: 'save_results_json', status: 'pending', position: { x: 350, y: 250 } },
  { id: '4', label: 'Generating discrepancy', backendKey: 'generate_discrepancy', status: 'pending', position: { x: 350, y: 350 } },
  { id: '5', label: 'Processing tariff', backendKey: 'process_tariff', status: 'pending', position: { x: 350, y: 450 } },
  { id: '6', label: 'Generating settlement letter', backendKey: 'generate_settlement_letter', status: 'pending', position: { x: 350, y: 550 } },
  { id: '7', label: 'Highlighting patient documents', backendKey: 'highlight_patient_documents', status: 'pending', position: { x: 650, y: 150 } },
];

const CustomNode = ({ label, status, position }) => (
  <div
    className="custom-node"
    style={{
      position: 'absolute',
      left: position.x,
      top: position.y,
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '30px',
      backgroundColor: '#fff',
      textAlign: 'center',
      width: '150px',
    }}
  >
    <div className="node-label">{label}</div>
    <div className="job-status">
      {status === 'completed' && (
        <img src={greenTick} style={{ width: '20px', height: '20px' }} />
      )}
      {status === 'in-progress' && (
        <FaSyncAlt className="fa-spin" style={{ color: '#9E9E9E' }} />
      )}
      {status === 'pending' && (
        <FaSyncAlt style={{ color: '#ccc' }} />
      )}
      {status === 'error' && (
        <FaExclamationCircle style={{ color: 'red' }} />
      )}
    </div>
  </div>
);

const JobPipeline = () => {
  const [jobNodes, setJobNodes] = useState(initialJobNodes);
  const navigate = useNavigate();
  const [allTasksCompleted, setAllTasksCompleted] = useState(false);
  const [error, setError] = useState(null);

  const updateJobNodesSequentially = useCallback((progress) => {
    setJobNodes((prevNodes) => {
      const updatedNodes = prevNodes.map((node) => ({
        ...node,
        status: progress[node.backendKey] || node.status
      }));

      const allCompleted = updatedNodes.every((node) => node.status === 'completed');
      setAllTasksCompleted(allCompleted);

      return updatedNodes;
    });
  }, []);

  const fetchProgress = useCallback(async () => {
    try {
      const response = await fetch('http://localhost:5000/save_pipeline');  // Update port if necessary
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      console.log('Progress fetched from backend:', data.progress);

      if (data && data.progress) {
        updateJobNodesSequentially(data.progress);
      } else {
        console.error('No progress data returned from backend');
      }
    } catch (error) {
      console.error('Error fetching job progress:', error);
      setError('Failed to fetch progress. Please try again later.');
    }
  }, [updateJobNodesSequentially]);

  useEffect(() => {
    const interval = setInterval(fetchProgress, 2000);
    return () => clearInterval(interval);
  }, [fetchProgress]);

  useEffect(() => {
    if (allTasksCompleted) {
      console.log('All tasks are completed! Redirecting to /claim-details...');
      navigate('/pipe');  // Update the route as per your application
    }
  }, [allTasksCompleted, navigate]);

  useEffect(() => {
    const startPipeline = async () => {
      try {
        const response = await fetch('http://localhost:5000/run_pipeline', { method: 'POST' });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log('Pipeline started successfully');
      } catch (error) {
        console.error('Error starting pipeline:', error);
        setError('Failed to start the pipeline. Please try again later.');
      }
    };

    startPipeline();
  }, []);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '600px' }}>
      <h2 style={{ textAlign: 'left', marginBottom: '20px', paddingLeft: '50px' }}>Initiating Claim Review</h2>

    <svg width="100%" height="100%">
    {/* Draw Connections Between Jobs Using BasisCurve */}
       <BasisCurve data={[[200, 90], [360, 90]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[200, 90], [360, 200]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[200, 90], [370, 280]]} stroke="gray" strokeWidth={2} />
         <BasisCurve data={[[200, 90], [360, 300], [360, 390]]} stroke="gray" strokeWidth={2} />
         <BasisCurve data={[[200, 90], [320, 310], [360, 490]]} stroke="gray" strokeWidth={2} />

        <BasisCurve data={[[500, 400], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
         <BasisCurve data={[[500, 300], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
         <BasisCurve data={[[500, 200], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[500, 100], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[500, 500], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
      </svg>


      {jobNodes.map((node) => (
        <CustomNode
          key={node.id}
          label={node.label}
          status={node.status}
          position={node.position}
        />
      ))}
    </div>
  );
};

export default JobPipeline;
