// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import './FileUploadPage.css'; // Ensure this is the correct path to your CSS file

// function JsonDisplayPage() {
//   const location = useLocation();
//   const jsonData = location.state?.jsonData;
//   const [showDropdown, setShowDropdown] = useState(false); // To show/hide the download dropdown

//   // Helper function to flatten JSON objects for CSV conversion
//   const flattenObject = (obj, parent = '', res = {}) => {
//     for (let key in obj) {
//       const propName = parent ? `${parent}_${key}` : key;
//       if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
//         flattenObject(obj[key], propName, res);
//       } else if (Array.isArray(obj[key])) {
//         res[propName] = obj[key]
//           .map((item) => (typeof item === 'object' ? JSON.stringify(item) : item))
//           .join(', ');
//       } else {
//         res[propName] = obj[key];
//       }
//     }
//     return res;
//   };

//   // Function to convert JSON to CSV format
//   const jsonToCsv = (jsonData) => {
//     if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
//       alert('No data available to download.');
//       return '';
//     }

//     const processArrayFields = (data) => {
//       let csvRows = [];
//       data.forEach((item) => {
//         Object.entries(item).forEach(([key, value]) => {
//           if (Array.isArray(value)) {
//             csvRows.push(`"${key.toUpperCase()}"`);
//             if (typeof value[0] === 'string') {
//               value.forEach((arrItem) => {
//                 csvRows.push(`"${arrItem}"`);
//               });
//             } else if (typeof value[0] === 'object') {
//               csvRows.push(Object.keys(value[0]).join(','));
//               value.forEach((subItem) => {
//                 const row = Object.values(subItem)
//                   .map((val) => `"${val}"`)
//                   .join(',');
//                 csvRows.push(row);
//               });
//             }
//             csvRows.push('');
//           } else if (typeof value === 'object') {
//             csvRows.push(`"${key.toUpperCase()}"`);
//             const flattenedObj = flattenObject(value);
//             Object.entries(flattenedObj).forEach(([subKey, subValue]) => {
//               csvRows.push(`"${subKey}","${subValue}"`);
//             });
//             csvRows.push('');
//           } else {
//             const escapedKey = ('' + key).replace(/"/g, '""');
//             const escapedValue = ('' + (value || '')).replace(/"/g, '""');
//             csvRows.push(`"${escapedKey}","${escapedValue}"`);
//           }
//         });
//         csvRows.push('');
//       });
//       return csvRows.join('\n');
//     };

//     let dataArray = [];

//     if (!Array.isArray(jsonData)) {
//       dataArray = [jsonData];
//     } else {
//       dataArray = jsonData;
//     }

//     const csvContent = processArrayFields(dataArray);

//     return csvContent;
//   };

//   // Function to handle download in JSON or CSV format
//   const handleDownload = (format) => {
//     if (!jsonData) {
//       alert('No data available to download.');
//       return;
//     }

//     let blob;
//     if (format === 'json') {
//       blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json;charset=utf-8' });
//     } else if (format === 'csv') {
//       const csvData = jsonToCsv(jsonData);
//       blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
//     }

//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = `processed_data.${format}`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   // Function to copy JSON to clipboard
//   const handleCopy = () => {
//     navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
//     alert('Copied to clipboard');
//   };

//   if (!jsonData) {
//     return <p>No JSON data available.</p>;
//   }

//   return (
//     <div className="json-view-container">
//       <h3 className="name">JSON</h3>
//       <textarea
//         readOnly
//         value={JSON.stringify(jsonData, null, 2)}
//         className="json-textarea"
//         rows="10"
//       />
//       <div className="json-buttons">
//         <div className="dropdown">
//           <button
//             className="json-download-button"
//             onClick={() => setShowDropdown(!showDropdown)}
//           >
//             Download
//           </button>
//           {showDropdown && (
//             <div className="dropdown-menu">
//               <button onClick={() => handleDownload('json')}>Download as JSON</button>
//               <button onClick={() => handleDownload('csv')}>Download as CSV</button>
//             </div>
//           )}
//         </div>
//         <button onClick={handleCopy} className="json-copy-button">Copy to clipboard</button>
//       </div>
//     </div>
//   );
// }

// export default JsonDisplayPage;






//2. this new component has the option to display and download the pdf

// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { Document, Page } from 'react-pdf';
// import { API_ENDPOINTS } from '../config';
// import './JsonDisplayPage.css';

// function JsonDisplayPage() {
//   const location = useLocation();
//   const jsonData = location.state?.jsonData;
//   const [leftPdfUrl, setLeftPdfUrl] = useState(null);
//   const [rightPdfUrl, setRightPdfUrl] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [numPages, setNumPages] = useState(null);
//   const [showDropdown, setShowDropdown] = useState(false);

//   // Helper function to flatten JSON objects for CSV conversion
//   const flattenObject = (obj, parent = '', res = {}) => {
//     for (let key in obj) {
//       const propName = parent ? `${parent}_${key}` : key;
//       if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
//         flattenObject(obj[key], propName, res);
//       } else if (Array.isArray(obj[key])) {
//         res[propName] = obj[key]
//           .map((item) => (typeof item === 'object' ? JSON.stringify(item) : item))
//           .join(', ');
//       } else {
//         res[propName] = obj[key];
//       }
//     }
//     return res;
//   };

//   // Function to convert JSON to CSV format
//   const jsonToCsv = (jsonData) => {
//     if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
//       alert('No data available to convert.');
//       return '';
//     }

//     const processArrayFields = (data) => {
//       let csvRows = [];
//       data.forEach((item) => {
//         Object.entries(item).forEach(([key, value]) => {
//           if (Array.isArray(value)) {
//             csvRows.push(`"${key.toUpperCase()}"`);
//             if (typeof value[0] === 'string') {
//               value.forEach((arrItem) => {
//                 csvRows.push(`"${arrItem}"`);
//               });
//             } else if (typeof value[0] === 'object') {
//               csvRows.push(Object.keys(value[0]).join(','));
//               value.forEach((subItem) => {
//                 const row = Object.values(subItem)
//                   .map((val) => `"${val}"`)
//                   .join(',');
//                 csvRows.push(row);
//               });
//             }
//             csvRows.push('');
//           } else if (typeof value === 'object') {
//             csvRows.push(`"${key.toUpperCase()}"`);
//             const flattenedObj = flattenObject(value);
//             Object.entries(flattenedObj).forEach(([subKey, subValue]) => {
//               csvRows.push(`"${subKey}","${subValue}"`);
//             });
//             csvRows.push('');
//           } else {
//             const escapedKey = ('' + key).replace(/"/g, '""');
//             const escapedValue = ('' + (value || '')).replace(/"/g, '""');
//             csvRows.push(`"${escapedKey}","${escapedValue}"`);
//           }
//         });
//         csvRows.push('');
//       });
//       return csvRows.join('\n');
//     };

//     let dataArray = Array.isArray(jsonData) ? jsonData : [jsonData];
//     return processArrayFields(dataArray);
//   };

//   const handlePdfGeneration = async () => {
//     if (!jsonData) {
//       alert('No data available for conversion.');
//       return;
//     }

//     const csvData = jsonToCsv(jsonData);
//     if (!csvData) return;

//     const csvBlob = new Blob([csvData], { type: 'text/csv' });
//     const formData = new FormData();
//     formData.append('file', csvBlob, 'data.csv');

//     setIsProcessing(true);

//     try {
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/convert-csv-to-pdf`, {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) throw new Error('Failed to generate PDF');

//       const blob = await response.blob();
//       const pdfUrl = URL.createObjectURL(blob);
//       setLeftPdfUrl(pdfUrl);
//       setRightPdfUrl(pdfUrl); // Set the same PDF for both sides initially
//     } catch (error) {
//       console.error('Error generating PDF:', error);
//       alert('Error generating PDF');
//     } finally {
//       setIsProcessing(false);
//     }
//   };

//   useEffect(() => {
//     handlePdfGeneration();
//   }, []);

//   const handleDownload = (format) => {
//     if (!jsonData) {
//       alert('No data available to download.');
//       return;
//     }

//     let blob;
//     let fileName;

//     switch (format) {
//       case 'pdf':
//         if (leftPdfUrl) {
//           const link = document.createElement('a');
//           link.href = leftPdfUrl;
//           link.download = 'processed_data.pdf';
//           document.body.appendChild(link);
//           link.click();
//           document.body.removeChild(link);
//         }
//         return;
//       case 'json':
//         blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json;charset=utf-8' });
//         fileName = 'processed_data.json';
//         break;
//       case 'csv':
//         const csvData = jsonToCsv(jsonData);
//         blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
//         fileName = 'processed_data.csv';
//         break;
//       default:
//         alert('Invalid format selected');
//         return;
//     }

//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = fileName;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   const handleCopy = () => {
//     navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
//     alert('Copied to clipboard');
//   };

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   if (!jsonData) {
//     return <p>No JSON data available.</p>;
//   }

//   return (
// <div className="pdf-comparison-container">
//       <h2>PDF Comparison</h2>

//       <div className="pdf-viewers">
//         <div className="pdf-viewer left-pdf">
//           <h3>Claim PDF</h3>
//           <div className="pdf-content">
//             {leftPdfUrl && (
//               <Document file={leftPdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
//                 {Array.from(new Array(numPages), (el, index) => (
//                   <Page key={`left_page_${index + 1}`} pageNumber={index + 1} width={600} />
//                 ))}
//               </Document>
//             )}
//           </div>
//         </div>

//         <div className="pdf-viewer right-pdf">
//           <h3>Processed PDF</h3>
//           <div className="pdf-content">
//             {rightPdfUrl && (
//               <Document file={rightPdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
//                 {Array.from(new Array(numPages), (el, index) => (
//                   <Page key={`right_page_${index + 1}`} pageNumber={index + 1} width={600} />
//                 ))}
//               </Document>
//             )}
//           </div>
//         </div>
//       </div>

//       <div className="action-buttons">
//         <div className="dropdown">
//           <button className="download-button" onClick={() => setShowDropdown(!showDropdown)}>
//             Download
//           </button>
//           {showDropdown && (
//             <div className="dropdown-menu">
//               <button onClick={() => handleDownload('pdf')}>Download as PDF</button>
//               <button onClick={() => handleDownload('json')}>Download as JSON</button>
//               <button onClick={() => handleDownload('csv')}>Download as CSV</button>
//             </div>
//           )}
//         </div>
//         {/* <button onClick={handleCopy} className="copy-button">
//           Copy JSON to clipboard
//         </button> */}
//       </div>
//     </div>
//   );
// }

// export default JsonDisplayPage;




// This gives the json output 

// import React, { useState, useEffect } from 'react';
// // Removed unnecessary import
// // import { useLocation } from 'react-router-dom';
// import './FileUploadPage.css'; // Ensure this is the correct path to your CSS file
// import { API_ENDPOINTS } from '../config';
// import jsonData from './processed_data.json'; // Import your JSON file

// function JsonDisplayPage() {
//   // Removed unnecessary code
//   // const location = useLocation();
//   // const jsonData = location.state?.jsonData;

//   const [pdfUrl, setPdfUrl] = useState(null); // Store the URL of the generated PDF
//   const [showDropdown, setShowDropdown] = useState(false); // To show/hide the download dropdown
//   const [isProcessing, setIsProcessing] = useState(false); // To track if the conversion is happening

//   // Helper function to flatten JSON objects for CSV conversion
//   const flattenObject = (obj, parent = '', res = {}) => {
//     for (let key in obj) {
//       const propName = parent ? `${parent}_${key}` : key;
//       if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
//         flattenObject(obj[key], propName, res);
//       } else if (Array.isArray(obj[key])) {
//         res[propName] = obj[key]
//           .map((item) => (typeof item === 'object' ? JSON.stringify(item) : item))
//           .join(', ');
//       } else {
//         res[propName] = obj[key];
//       }
//     }
//     return res;
//   };

//   // Function to convert JSON to CSV format
//   const jsonToCsv = (jsonData) => {
//     if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
//       alert('No data available to convert.');
//       return '';
//     }

//     const processArrayFields = (data) => {
//       let csvRows = [];
//       data.forEach((item) => {
//         Object.entries(item).forEach(([key, value]) => {
//           if (Array.isArray(value)) {
//             csvRows.push(`"${key.toUpperCase()}"`);
//             if (typeof value[0] === 'string') {
//               value.forEach((arrItem) => {
//                 csvRows.push(`"${arrItem}"`);
//               });
//             } else if (typeof value[0] === 'object') {
//               csvRows.push(Object.keys(value[0]).join(','));
//               value.forEach((subItem) => {
//                 const row = Object.values(subItem)
//                   .map((val) => `"${val}"`)
//                   .join(',');
//                 csvRows.push(row);
//               });
//             }
//             csvRows.push('');
//           } else if (typeof value === 'object') {
//             csvRows.push(`"${key.toUpperCase()}"`);
//             const flattenedObj = flattenObject(value);
//             Object.entries(flattenedObj).forEach(([subKey, subValue]) => {
//               csvRows.push(`"${subKey}","${subValue}"`);
//             });
//             csvRows.push('');
//           } else {
//             const escapedKey = ('' + key).replace(/"/g, '""');
//             const escapedValue = ('' + (value || '')).replace(/"/g, '""');
//             csvRows.push(`"${escapedKey}","${escapedValue}"`);
//           }
//         });
//         csvRows.push('');
//       });
//       return csvRows.join('\n');
//     };

//     let dataArray = [];

//     if (!Array.isArray(jsonData)) {
//       dataArray = [jsonData];
//     } else {
//       dataArray = jsonData;
//     }

//     const csvContent = processArrayFields(dataArray);

//     return csvContent;
//   };

//   // Function to handle the conversion and fetching PDF
//   const handlePdfGeneration = async () => {
//     if (!jsonData) {
//       alert('No data available for conversion.');
//       return;
//     }

//     // Convert JSON to CSV format
//     const csvData = jsonToCsv(jsonData);

//     if (!csvData) {
//       return;
//     }

//     // Create a Blob from the CSV data
//     const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
//     const formData = new FormData();
//     formData.append('file', csvBlob, 'data.csv');


//     setIsProcessing(true);

//     try {
//       // Send the CSV file to the FastAPI endpoint to convert to PDF
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/convert-csv-to-pdf`, {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Failed to generate PDF');
//       }

//       const blob = await response.blob(); // Get the PDF file as a blob
//       const pdfUrl = URL.createObjectURL(blob); // Create a URL for the PDF file
//       setPdfUrl(pdfUrl); // Store the PDF URL to display in iframe and for download
//     } catch (error) {
//       console.error('Error generating PDF:', error);
//       alert('Error generating PDF');
//     } finally {
//       setIsProcessing(false);
//     }
//   };

//   // Automatically generate PDF when the component mounts
//   useEffect(() => {
//     handlePdfGeneration(); // Automatically call PDF generation when page loads
//   }, []); // Empty dependency array ensures this runs only once when the component mounts

//   // Function to handle download in JSON or CSV format
//   const handleDownload = (format) => {
//     if (!jsonData) {
//       alert('No data available to download.');
//       return;
//     }

//     let blob;
//     if (format === 'json') {
//       blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json;charset=utf-8' });
//     } else if (format === 'csv') {
//       const csvData = jsonToCsv(jsonData);
//       blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
//     }

//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = `processed_data.${format}`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   // Function to copy JSON to clipboard
//   const handleCopy = () => {
//     navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
//     alert('Copied to clipboard');
//   };

//   if (!jsonData) {
//     return <p>No JSON data available.</p>;
//   }

//   return (
//     <div className="json-view-container">
//       <h3 className="name">PDF Conversion</h3>

//       {/* Dropdown to download JSON or CSV */}
//       <div className="json-buttons">
//         <div className="dropdown">
//           <button
//             className="json-download-button"
//             onClick={() => setShowDropdown(!showDropdown)}
//           >
//             Download
//           </button>
//           {showDropdown && (
//             <div className="dropdown-menu">
//               <button onClick={() => handleDownload('json')}>Download as JSON</button>
//               <button onClick={() => handleDownload('csv')}>Download as CSV</button>
//             </div>
//           )}
//         </div>

//         <button onClick={handleCopy} className="json-copy-button">
//           Copy JSON to clipboard
//         </button>
//       </div>

//       {/* Display PDF in an iframe if the URL is available */}
//       {pdfUrl && (
//         <div>
//           <iframe src={pdfUrl} width="100%" height="500px" title="PDF Preview" />
//           <a href={pdfUrl} download="processed.pdf" className="json-download-button">
//             Download PDF
//           </a>
//         </div>
//       )}
//     </div>
//   );
// }

// export default JsonDisplayPage;











// 3rd testing to pass the left pdf from the url

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { API_ENDPOINTS } from '../config'; // Update this import based on your structure
import './JsonDisplayPage.css';

function JsonDisplayPage() {
  const location = useLocation();
  const jsonData = location.state?.jsonData;
  const [leftPdfUrl, setLeftPdfUrl] = useState(null);
  const [rightPdfUrl, setRightPdfUrl] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [numPagesLeft, setNumPagesLeft] = useState(null); // For left PDF
  const [numPagesRight, setNumPagesRight] = useState(null); // For right PDF
  const [showDropdown, setShowDropdown] = useState(false);

  // Fetch the PDF for the left container from the /get_upload_pdf endpoint
  const fetchLeftPdf = async () => {
    setIsProcessing(true);
    try {
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/get_upload_pdf?docId=12345`); // Pass docId as needed
      if (!response.ok) {
        throw new Error('Failed to fetch PDF from the server');
      }
      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);
      setLeftPdfUrl(pdfUrl); // Set left PDF URL
    } catch (error) {
      console.error('Error fetching left PDF:', error);
      alert('Error fetching PDF for Claim');
    } finally {
      setIsProcessing(false);
    }
  };

  // Call the fetchLeftPdf on component mount
  useEffect(() => {
    fetchLeftPdf(); // Fetch the left PDF
    handlePdfGeneration(); // Generate the right PDF from JSON data
  }, []);

  // Helper function to flatten JSON objects for CSV conversion
  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      const propName = parent ? `${parent}_${key}` : key;
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        flattenObject(obj[key], propName, res);
      } else if (Array.isArray(obj[key])) {
        res[propName] = obj[key]
          .map((item) => (typeof item === 'object' ? JSON.stringify(item) : item))
          .join(', ');
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  // Function to convert JSON to CSV format
  const jsonToCsv = (jsonData) => {
    if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
      alert('No data available to convert.');
      return '';
    }

    const processArrayFields = (data) => {
      let csvRows = [];
      data.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            csvRows.push(`"${key.toUpperCase()}"`);
            if (typeof value[0] === 'string') {
              value.forEach((arrItem) => {
                csvRows.push(`"${arrItem}"`);
              });
            } else if (typeof value[0] === 'object') {
              csvRows.push(Object.keys(value[0]).join(','));
              value.forEach((subItem) => {
                const row = Object.values(subItem)
                  .map((val) => `"${val}"`)
                  .join(',');
                csvRows.push(row);
              });
            }
            csvRows.push('');
          } else if (typeof value === 'object') {
            csvRows.push(`"${key.toUpperCase()}"`);
            const flattenedObj = flattenObject(value);
            Object.entries(flattenedObj).forEach(([subKey, subValue]) => {
              csvRows.push(`"${subKey}","${subValue}"`);
            });
            csvRows.push('');
          } else {
            const escapedKey = ('' + key).replace(/"/g, '""');
            const escapedValue = ('' + (value || '')).replace(/"/g, '""');
            csvRows.push(`"${escapedKey}","${escapedValue}"`);
          }
        });
        csvRows.push('');
      });
      return csvRows.join('\n');
    };

    let dataArray = Array.isArray(jsonData) ? jsonData : [jsonData];
    return processArrayFields(dataArray);
  };

  // Generate the right PDF from the CSV data and show it in the right container
  const handlePdfGeneration = async () => {
    if (!jsonData) {
      alert('No data available for conversion.');
      return;
    }

    const csvData = jsonToCsv(jsonData);
    if (!csvData) return;

    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const formData = new FormData();
    formData.append('file', csvBlob, 'data.csv');

    setIsProcessing(true);

    try {
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/convert-csv-to-pdf`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to generate PDF');

      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);
      setRightPdfUrl(pdfUrl); // Set right PDF URL
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating Processed PDF');
    } finally {
      setIsProcessing(false);
    }
  };

  // Separate onLoadSuccess handlers for left and right PDFs
  const onDocumentLoadSuccessLeft = ({ numPages }) => {
    setNumPagesLeft(numPages); // Set number of pages for the left PDF
  };

  const onDocumentLoadSuccessRight = ({ numPages }) => {
    setNumPagesRight(numPages); // Set number of pages for the right PDF
  };

  const handleDownload = (format) => {
    if (!jsonData) {
      alert('No data available to download.');
      return;
    }
  
    let blob;
    let fileName;
  
    switch (format) {
      case 'pdf':
        if (rightPdfUrl) {  // Use the rightPdfUrl for download
          const link = document.createElement('a');
          link.href = rightPdfUrl;
          link.download = 'processed_data.pdf';  // Updated file name to represent processed data
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          alert('No processed PDF available for download.');
        }
        return;
      case 'json':
        blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json;charset=utf-8' });
        fileName = 'processed_data.json';
        break;
      case 'csv':
        const csvData = jsonToCsv(jsonData);
        blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        fileName = 'processed_data.csv';
        break;
      default:
        alert('Invalid format selected');
        return;
    }
  
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <div className="pdf-comparison-container">
      <h2>Extracted Data</h2>

      <div className="pdf-viewers">
        <div className="pdf-viewer left-pdf">
          <h3>Claim PDF</h3>
          <div className="pdf-content">
            {leftPdfUrl && (
              <Document file={leftPdfUrl} onLoadSuccess={onDocumentLoadSuccessLeft}>
                {Array.from(new Array(numPagesLeft), (el, index) => (
                  <Page key={`left_page_${index + 1}`} pageNumber={index + 1} width={600} />
                ))}
              </Document>
            )}
          </div>
        </div>

        <div className="pdf-viewer right-pdf">
          <h3>Processed PDF</h3>
          <div className="pdf-content">
            {rightPdfUrl && (
              <Document file={rightPdfUrl} onLoadSuccess={onDocumentLoadSuccessRight}>
                {Array.from(new Array(numPagesRight), (el, index) => (
                  <Page key={`right_page_${index + 1}`} pageNumber={index + 1} width={600} />
                ))}
              </Document>
            )}
          </div>
        </div>
      </div>

      <div className="action-buttons">
        <div className="dropdown">
          <button className="download-button" onClick={() => setShowDropdown(!showDropdown)}>
            Download
          </button>
          {showDropdown && (
            <div className="dropdown-menu">
              <button onClick={() => handleDownload('pdf')}>Download as PDF</button>
              <button onClick={() => handleDownload('json')}>Download as JSON</button>
              <button onClick={() => handleDownload('csv')}>Download as CSV</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JsonDisplayPage;
