// // 1. which is working properly

// import React, { useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import './FileUploadPage.css';
// import uploadIcon from '../icons/upload-icon.png';
// import fileIcon from './file-icon.png'; // Ensure this path is correct
// import { API_ENDPOINTS } from '../config';

// function FileUploadPage() {
//   const [files, setFiles] = useState([]);   // To store selected files
//   const [progress, setProgress] = useState({});   // For file upload progress
//   const [uploading, setUploading] = useState({}); // For tracking upload state
//   const [uploadStarted, setUploadStarted] = useState(false); // To trigger upload after button click
//   const [processing, setProcessing] = useState(false); // To show processing animation
//   const [jsonData, setJsonData] = useState(null); // To store the final JSON data
//   const [showDropdown, setShowDropdown] = useState(false); // To show/hide the download dropdown

//   // Handle file drop or selection
//   const onDrop = (acceptedFiles) => {
//     setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: {
//       'image/*': ['.png', '.jpg', '.jpeg', '.pdf'], // Accept PNG, JPG, PDF
//     },
//   });

//   // Handle upload when the "Upload" button is clicked
//   const handleUpload = () => {
//     setUploadStarted(true);  // Indicate that upload has started
//     files.forEach(file => uploadFile(file));  // Start uploading each file
//   };

//   const uploadFile = async (file) => {
//     setUploading((prevUploading) => ({ ...prevUploading, [file.name]: true }));
//     setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 0 }));

//     const formData = new FormData();
//     formData.append('file', file);

//     // Simulated upload progress
//     const uploadDuration = 2000;  // 2 seconds
//     const intervalDuration = 100; // 0.1 second
//     const increment = 100 / (uploadDuration / intervalDuration);

//     let currentProgress = 0;

//     const interval = setInterval(() => {
//       currentProgress += increment;
//       setProgress((prevProgress) => ({ ...prevProgress, [file.name]: currentProgress }));

//       if (currentProgress >= 100) {
//         clearInterval(interval);
//         setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 100 }));
//         setUploading((prevUploading) => ({ ...prevUploading, [file.name]: false }));

//         // After upload finishes, move to processing stage
//         if (Object.values(uploading).every((status) => !status)) {
//           setProcessing(true);
//           processDocuments();
//         }
//       }
//     }, intervalDuration);

//     // Upload the file to the server
//     try {
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/test_upload`, {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         console.error('Upload failed');
//       } else {
//         console.log('Upload successful');
//       }
//     } catch (error) {
//       console.error('Upload error', error);
//     }
//   };

//   // Simulate processing documents on the server and getting JSON data
//   const processDocuments = async () => {
//     try {
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/extract_structure`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json(); // Assume backend returns processed JSON
//         setJsonData(data); // Store JSON data
//         setProcessing(false); // Stop processing animation
//       } else {
//         console.error('Document processing failed');
//       }
//     } catch (error) {
//       console.error('Error processing documents:', error);
//       setProcessing(false);
//     }
//   };


// const jsonToCsv = (jsonData) => {
//     if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
//         alert('No data available to download.');
//         return '';
//     }

//     // Flatten object
//     const flattenObject = (obj, parent = '', res = {}) => {
//         for (let key in obj) {
//             const propName = parent ? `${parent}_${key}` : key;
//             if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
//                 flattenObject(obj[key], propName, res); // Recursively flatten objects
//             } else if (Array.isArray(obj[key])) {
//                 // Join array values or further process them
//                 res[propName] = obj[key].map(item => (typeof item === 'object' ? JSON.stringify(item) : item)).join(', ');
//             } else {
//                 res[propName] = obj[key];
//             }
//         }
//         return res;
//     };

//     const processArrayFields = (data) => {
//         let csvRows = [];
//         data.forEach(item => {
//             Object.entries(item).forEach(([key, value]) => {
//                 if (Array.isArray(value)) {
//                     // Handle arrays
//                     csvRows.push(`"${key.toUpperCase()}"`); // Section header
//                     if (typeof value[0] === 'string') {
//                         value.forEach(arrItem => {
//                             csvRows.push(`"${arrItem}"`); // Each array string as a row
//                         });
//                     } else if (typeof value[0] === 'object') {
//                         csvRows.push(Object.keys(value[0]).join(',')); // Add table headers for array of objects
//                         value.forEach(subItem => {
//                             const row = Object.values(subItem).map(val => `"${val}"`).join(',');
//                             csvRows.push(row); // Add each object as a row
//                         });
//                     }
//                     csvRows.push(''); // Blank line after each section
//                 } else if (typeof value === 'object') {
//                     // Handle nested objects
//                     csvRows.push(`"${key.toUpperCase()}"`);
//                     const flattenedObj = flattenObject(value);
//                     Object.entries(flattenedObj).forEach(([subKey, subValue]) => {
//                         csvRows.push(`"${subKey}","${subValue}"`);
//                     });
//                     csvRows.push(''); // Blank line after each section
//                 } else {
//                     // Handle key-value pairs
//                     const escapedKey = ('' + key).replace(/"/g, '""');
//                     const escapedValue = ('' + (value || '')).replace(/"/g, '""');
//                     csvRows.push(`"${escapedKey}","${escapedValue}"`);
//                 }
//             });
//             csvRows.push(''); // Blank line after each object
//         });
//         return csvRows.join('\n');
//     };

//     let dataArray = [];

//     // If jsonData is a single object, wrap it in an array
//     if (!Array.isArray(jsonData)) {
//         dataArray = [jsonData];
//     } else {
//         dataArray = jsonData;
//     }

//     const csvContent = processArrayFields(dataArray);

//     return csvContent;
// };



  

//   // Function to download file (JSON or CSV)
//   const handleDownload = (format) => {
//     if (!jsonData) {
//       alert('No data available to download.');
//       return;
//     }
  
//     let blob;
//     if (format === 'json') {
//       blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json;charset=utf-8' });
//     } else if (format === 'csv') {
//       const csvData = jsonToCsv(jsonData);
//       blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
//     }
  
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = `processed_data.${format}`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     setShowDropdown(false); // Hide the dropdown after download
//   };
  
//   // Function to copy JSON to clipboard
//   const handleCopy = () => {
//     navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
//     alert("Copied to clipboard");
//   };

//   // Conditional rendering: Show processing animation or JSON output
//   if (processing) {
//     return (
//       <div className="processing-container">
//         <h3>Processing Documents...</h3>
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   if (jsonData) {
//     return (
//       <div className="json-view-container">
//         <h3 className="name">JSON</h3>
//         <textarea
//           readOnly
//           value={JSON.stringify(jsonData, null, 2)}
//           className="json-textarea"
//           rows="10"
//         />
//         <div className="json-buttons">
//           <div className="dropdown">
//             <button
//               className="json-download-button"
//               onClick={() => setShowDropdown(!showDropdown)}
//             >
//               Download
//             </button>
//             {showDropdown && (
//               <div className="dropdown-menu">
//                 <button onClick={() => handleDownload('json')}>Download as JSON</button>
//                 <button onClick={() => handleDownload('csv')}>Download as CSV</button>
//               </div>
//             )}
//           </div>
//           <button onClick={handleCopy} className="json-copy-button">Copy to clipboard</button>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="upload-container">
//       <div {...getRootProps({ className: 'dropzone' })}>
//         <input {...getInputProps()} />
//         <div className="upload-content">
//           <img src={uploadIcon} alt="Upload" className="upload-icon" />
//           <p>Drag and drop here<br />or <span className="browse-link">browse</span></p>
//         </div>
//       </div>

//       <p className="file-formats">Supported formats: PNG, JPG, PDF</p>

//       <div className="file-info">
//         {files.length > 0 && (
//           <div className="file-preview">
//             {files.map((file, index) => (
//               <div key={index} className="file-item">
//                 <div className="file-details">
//                   <img src={fileIcon} alt="File Icon" className="file-icon" />
//                   <span className="file-name">{file.name}</span>
//                   {uploadStarted && uploading[file.name] && (
//                     <span className="file-progress">{Math.round(progress[file.name] || 0)}%</span>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       {files.length > 0 && (
//         <button className="upload-button" onClick={handleUpload}>
//           Upload
//         </button>
//       )}
//     </div>
//   );
// }

// export default FileUploadPage;








//2. Testing component


// import React, { useState, useEffect } from 'react';
// import { useDropzone } from 'react-dropzone';
// import './FileUploadPage.css';
// import uploadIcon from '../icons/upload-icon.png';
// import fileIcon from './file-icon.png'; // Ensure this path is correct
// import { API_ENDPOINTS } from '../config';
// import Lottie from "react-lottie";

// function FileUploadPage() {
//   const [files, setFiles] = useState([]);   // To store selected files
//   const [progress, setProgress] = useState({});   // For file upload progress
//   const [uploading, setUploading] = useState({}); // For tracking upload state
//   const [uploadStarted, setUploadStarted] = useState(false); // To trigger upload after button click
//   const [processing, setProcessing] = useState(false); // To show processing animation
//   const [jsonData, setJsonData] = useState(null); // To store the final JSON data
//   const [showDropdown, setShowDropdown] = useState(false); // To show/hide the download dropdown
//   const [steps, setSteps] = useState([false, false, false, false, false]);
//   const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);
//   const [animationProgress, setAnimationProgress] = useState(0);

//   // Lottie animations
//   const animations = [
//     "https://lottie.host/3fea76e0-e3e0-49f7-acc9-22d109283b28/wc003s0mwI.json", 
//     "https://lottie.host/108ac2ee-a698-41de-bd96-adf405839e07/ZVcciN37eg.json"
//   ];

//   // Handle file drop or selection
//   const onDrop = (acceptedFiles) => {
//     setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: {
//       'image/*': ['.png', '.jpg', '.jpeg', '.pdf'], // Accept PNG, JPG, PDF
//     },
//   });

//   // Handle upload when the "Upload" button is clicked
//   const handleUpload = () => {
//     setUploadStarted(true);  // Indicate that upload has started
//     files.forEach(file => uploadFile(file));  // Start uploading each file
//   };

//   const uploadFile = async (file) => {
//     setUploading((prevUploading) => ({ ...prevUploading, [file.name]: true }));
//     setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 0 }));

//     const formData = new FormData();
//     formData.append('file', file);

//     // Simulated upload progress
//     const uploadDuration = 2000;  // 2 seconds
//     const intervalDuration = 100; // 0.1 second
//     const increment = 100 / (uploadDuration / intervalDuration);

//     let currentProgress = 0;

//     const interval = setInterval(() => {
//       currentProgress += increment;
//       setProgress((prevProgress) => ({ ...prevProgress, [file.name]: currentProgress }));

//       if (currentProgress >= 100) {
//         clearInterval(interval);
//         setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 100 }));
//         setUploading((prevUploading) => ({ ...prevUploading, [file.name]: false }));

//         // After upload finishes, move to processing stage
//         if (Object.values(uploading).every((status) => !status)) {
//           setProcessing(true);
//           setSteps((prevSteps) => [true, true, false, false, false]); // First two steps complete
//           setAnimationProgress(45); // Initial progress
//           processDocuments();
//         }
//       }
//     }, intervalDuration);

//     // Upload the file to the server
//     try {
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/test_upload`, {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         console.error('Upload failed');
//       } else {
//         console.log('Upload successful');
//       }
//     } catch (error) {
//       console.error('Upload error', error);
//     }
//   };

//   // Simulate processing documents on the server and getting JSON data
//   const processDocuments = async () => {
//     try {
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/extract_structure`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json(); // Assume backend returns processed JSON
//         setSteps([true, true, true, true, true]); // All steps complete
//         setAnimationProgress(100); // Final progress
//         setTimeout(() => {
//           setJsonData(data); // Store JSON data
//           setProcessing(false); // Stop processing animation
//         }, 1000); // Short delay before showing JSON data
//       } else {
//         console.error('Document processing failed');
//       }
//     } catch (error) {
//       console.error('Error processing documents:', error);
//       setProcessing(false);
//     }
//   };

//   const jsonToCsv = (jsonData) => {
//     if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
//         alert('No data available to download.');
//         return '';
//     }

//     const flattenObject = (obj, parent = '', res = {}) => {
//         for (let key in obj) {
//             const propName = parent ? `${parent}_${key}` : key;
//             if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
//                 flattenObject(obj[key], propName, res);
//             } else if (Array.isArray(obj[key])) {
//                 res[propName] = obj[key].map(item => (typeof item === 'object' ? JSON.stringify(item) : item)).join(', ');
//             } else {
//                 res[propName] = obj[key];
//             }
//         }
//         return res;
//     };

//     const processArrayFields = (data) => {
//         let csvRows = [];
//         data.forEach(item => {
//             Object.entries(item).forEach(([key, value]) => {
//                 if (Array.isArray(value)) {
//                     csvRows.push(`"${key.toUpperCase()}"`);
//                     if (typeof value[0] === 'string') {
//                         value.forEach(arrItem => {
//                             csvRows.push(`"${arrItem}"`);
//                         });
//                     } else if (typeof value[0] === 'object') {
//                         csvRows.push(Object.keys(value[0]).join(','));
//                         value.forEach(subItem => {
//                             const row = Object.values(subItem).map(val => `"${val}"`).join(',');
//                             csvRows.push(row);
//                         });
//                     }
//                     csvRows.push('');
//                 } else if (typeof value === 'object') {
//                     csvRows.push(`"${key.toUpperCase()}"`);
//                     const flattenedObj = flattenObject(value);
//                     Object.entries(flattenedObj).forEach(([subKey, subValue]) => {
//                         csvRows.push(`"${subKey}","${subValue}"`);
//                     });
//                     csvRows.push('');
//                 } else {
//                     const escapedKey = ('' + key).replace(/"/g, '""');
//                     const escapedValue = ('' + (value || '')).replace(/"/g, '""');
//                     csvRows.push(`"${escapedKey}","${escapedValue}"`);
//                 }
//             });
//             csvRows.push('');
//         });
//         return csvRows.join('\n');
//     };

//     let dataArray = [];

//     if (!Array.isArray(jsonData)) {
//         dataArray = [jsonData];
//     } else {
//         dataArray = jsonData;
//     }

//     const csvContent = processArrayFields(dataArray);

//     return csvContent;
//   };

//   const handleDownload = (format) => {
//     if (!jsonData) {
//       alert('No data available to download.');
//       return;
//     }
  
//     let blob;
//     if (format === 'json') {
//       blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json;charset=utf-8' });
//     } else if (format === 'csv') {
//       const csvData = jsonToCsv(jsonData);
//       blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
//     }
  
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = `processed_data.${format}`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     setShowDropdown(false); // Hide the dropdown after download
//   };
  
//   const handleCopy = () => {
//     navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
//     alert("Copied to clipboard");
//   };

//   // Lottie animation configuration
//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     path: animations[currentAnimationIndex], // Use the current animation URL
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice",
//     },
//   };

//   useEffect(() => {
//     const toggleInterval = setInterval(() => {
//       setCurrentAnimationIndex((prevIndex) => (prevIndex + 1) % animations.length);
//     }, 5000); // Switch animation every 5 seconds

//     return () => clearInterval(toggleInterval); // Clear interval on component unmount
//   }, [animations.length]);

//   // Conditional rendering: Show processing animation or JSON output
//   if (processing) {
//     return (
//       <div className="processing-container">
//         <h3>Processing Documents...</h3>
//         <div className="lottie-container">
//           <Lottie key={currentAnimationIndex} options={defaultOptions} height={100} width={100} />
//         </div>
//         <div className="progress-bar">
//           <div className="progress" style={{ width: `${animationProgress}%` }}></div>
//         </div>
//         <ul className="animation-list">
//           <li>
//             <span>Uploading files...</span>
//             <span className={`checkmark ${steps[0] ? "checked" : ""}`}>✔</span>
//           </li>
//           <li>
//             <span>Extracting data...</span>
//             <span className={`checkmark ${steps[1] ? "checked" : ""}`}>✔</span>
//           </li>
//           <li>
//             <span>Processing document structure...</span>
//             <span className={`checkmark ${steps[2] ? "checked" : ""}`}>✔</span>
//           </li>
//           <li>
//             <span>Analyzing content...</span>
//             <span className={`checkmark ${steps[3] ? "checked" : ""}`}>✔</span>
//           </li>
//           <li>
//             <span>Generating results...</span>
//             <span className={`checkmark ${steps[4] ? "checked" : ""}`}>✔</span>
//           </li>
//         </ul>
//       </div>
//     );
//   }

//   if (jsonData) {
//     return (
//       <div className="json-view-container">
//         <h3 className="name">JSON</h3>
//         <textarea
//           readOnly
//           value={JSON.stringify(jsonData, null, 2)}
//           className="json-textarea"
//           rows="10"
//         />
//         <div className="json-buttons">
//           <div className="dropdown">
//             <button
//               className="json-download-button"
//               onClick={() => setShowDropdown(!showDropdown)}
//             >
//               Download
//             </button>
//             {showDropdown && (
//               <div className="dropdown-menu">
//                 <button onClick={() => handleDownload('json')}>Download as JSON</button>
//                 <button onClick={() => handleDownload('csv')}>Download as CSV</button>
//               </div>
//             )}
//           </div>
//           <button onClick={handleCopy} className="json-copy-button">Copy to clipboard</button>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="upload-container">
//       <div {...getRootProps({ className: 'dropzone' })}>
//         <input {...getInputProps()} />
//         <div className="upload-content">
//           <img src={uploadIcon} alt="Upload" className="upload-icon" />
//           <p>Drag and drop here<br />or <span className="browse-link">browse</span></p>
//         </div>
//       </div>

//       <p className="file-formats">Supported formats: PNG, JPG, PDF</p>

//       <div className="file-info">
//         {files.length > 0 && (
//           <div className="file-preview">
//             {files.map((file, index) => (
//               <div key={index} className="file-item">
//                 <div className="file-details">
//                   <img src={fileIcon} alt="File Icon" className="file-icon" />
//                   <span className="file-name">{file.name}</span>
//                   {uploadStarted && uploading[file.name] && (
//                     <span className="file-progress">{Math.round(progress[file.name] || 0)}%</span>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       {files.length > 0 && (
//         <button className="upload-button" onClick={handleUpload}>
//           Upload
//         </button>
//       )}
//     </div>
//   );
// }

// export default FileUploadPage;







// 3. seperating the components


import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './FileUploadPage.css';
import './AnimationPage.css'
import uploadIcon from '../icons/upload-icon.png';
import fileIcon from './file-icon.png'; // Ensure this path is correct
import { API_ENDPOINTS } from '../config';
import Lottie from "react-lottie";

function FileUploadPage() {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [uploading, setUploading] = useState({});
  const [uploadStarted, setUploadStarted] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [steps, setSteps] = useState([false, false, false, false, false]);
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);
  const [animationProgress, setAnimationProgress] = useState(0);
  
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Lottie animations
  const animations = [
    "https://lottie.host/3fea76e0-e3e0-49f7-acc9-22d109283b28/wc003s0mwI.json", 
    "https://lottie.host/108ac2ee-a698-41de-bd96-adf405839e07/ZVcciN37eg.json"
  ];

  // Handle file drop or selection
  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.pdf'],
    },
  });

  const handleUpload = () => {
    setUploadStarted(true);
    files.forEach(file => uploadFile(file));
  };

  const uploadFile = async (file) => {
    setUploading((prevUploading) => ({ ...prevUploading, [file.name]: true }));
    setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 0 }));

    const formData = new FormData();
    formData.append('file', file);

    const uploadDuration = 2000;
    const intervalDuration = 100;
    const increment = 100 / (uploadDuration / intervalDuration);

    let currentProgress = 0;

    const interval = setInterval(() => {
      currentProgress += increment;
      setProgress((prevProgress) => ({ ...prevProgress, [file.name]: currentProgress }));

      if (currentProgress >= 100) {
        clearInterval(interval);
        setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 100 }));
        setUploading((prevUploading) => ({ ...prevUploading, [file.name]: false }));

        if (Object.values(uploading).every((status) => !status)) {
          setProcessing(true);
          setSteps((prevSteps) => [true, true, false, false, false]);
          setAnimationProgress(45);
          processDocuments();
        }
      }
    }, intervalDuration);

    try {
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/test_upload`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        console.error('Upload failed');
      } else {
        console.log('Upload successful');
      }
    } catch (error) {
      console.error('Upload error', error);
    }
  };

  const processDocuments = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/extract_structure`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSteps([true, true, true, true, true]);
        setAnimationProgress(100);
        setTimeout(() => {
          setProcessing(false);
          navigate("/json-display", { state: { jsonData: data } }); // Navigate to the JSON display page
        }, 1000);
      } else {
        console.error('Document processing failed');
      }
    } catch (error) {
      console.error('Error processing documents:', error);
      setProcessing(false);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: animations[currentAnimationIndex],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const toggleInterval = setInterval(() => {
      setCurrentAnimationIndex((prevIndex) => (prevIndex + 1) % animations.length);
    }, 5000);

    return () => clearInterval(toggleInterval);
  }, [animations.length]);

  if (processing) {
    return (
      <div className="processing-container">
        <h3>Processing Documents...</h3>
        <div className="lottie-container">
          <Lottie key={currentAnimationIndex} options={defaultOptions} height={100} width={100} />
        </div>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${animationProgress}%` }}></div>
        </div>
        <ul className="animation-list">
          <li>
            <span>Uploading files...</span>
            <span className={`checkmark ${steps[0] ? "checked" : ""}`}>✔</span>
          </li>
          <li>
            <span>Extracting data...</span>
            <span className={`checkmark ${steps[1] ? "checked" : ""}`}>✔</span>
          </li>
          <li>
            <span>Processing document structure...</span>
            <span className={`checkmark ${steps[2] ? "checked" : ""}`}>✔</span>
          </li>
          <li>
            <span>Analyzing content...</span>
            <span className={`checkmark ${steps[3] ? "checked" : ""}`}>✔</span>
          </li>
          <li>
            <span>Generating results...</span>
            <span className={`checkmark ${steps[4] ? "checked" : ""}`}>✔</span>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="upload-container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className="upload-content">
          <img src={uploadIcon} alt="Upload" className="upload-icon" />
          <p>Drag and drop here<br />or <span className="browse-link">browse</span></p>
        </div>
      </div>

      <p className="file-formats">Supported formats: PNG, JPG, PDF</p>

      <div className="file-info">
        {files.length > 0 && (
          <div className="file-preview">
            {files.map((file, index) => (
              <div key={index} className="file-item">
                <div className="file-details">
                  <img src={fileIcon} alt="File Icon" className="file-icon" />
                  <span className="file-name">{file.name}</span>
                  {uploadStarted && uploading[file.name] && (
                    <span className="file-progress">{Math.round(progress[file.name] || 0)}%</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {files.length > 0 && (
        <button className="upload-button" onClick={handleUpload}>
          Upload
        </button>
      )}
    </div>
  );
}

export default FileUploadPage;
